.sideMenu {
    position: fixed;
    top: 50px;
    right: 0;
    width: 230px;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    transform: translateX(100%);
    transition: all 0.4s;
    z-index: 9999;
}

.sideMenu button {
    display: block;
    margin: 0 auto;
    padding: 8px 20px;
    font-size: 3vw;
    text-transform: uppercase;
}

.sideMenu__open {
    transform: translateX(0%);
}

.sideMenu ul {
    padding: 0 !important;
    margin: 0 !important;
}

.sideMenu li {
    padding: 1.5rem 3rem;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.sideMenu li a {
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2.8vw;
    text-transform: uppercase;
}

.sideMenu a:hover {
    color: orange;
}

.sideMenu img {
    width: 40vw;
}

/* ========================== BACKDROP */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 8888;
}

.backdrop__open {
    display: block;
}