.other_main_bg_cover {
    height: 80vh;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(https://ik.imagekit.io/workcations/gallery/triplist/main-cover.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    overflow: hidden;
    z-index: 1;
    padding-top: 80px;
    position: relative;
}

.other_main_bg_cover h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.trip_heading {
    font-size: 3vw !important;
}
.about-buttons-header{
    margin-top: 350px !important;
}
.about-buttons-header button{
    margin: 0 100px;
    padding: 10px 20px;
    width: 150px;
    border-radius: 25px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    background-color: #fecc2a;
    transition: all 0.3s;
}
.about-buttons-header button:hover{
    margin: 0 100px;
    padding: 10px 20px;
    width: 150px;
    border-radius: 25px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    background-color: #ffffff;
}

@media screen and (max-width:478px) {
    .other_main_bg_cover {
        padding-top: 0;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
    }

    .trip_heading {
        font-size: 2rem !important;
        padding: 10px !important;
    }
}

@media screen and (max-width:1050px) {
    .about-buttons-header button{
        margin: 0 50px;
        padding: 10px 20px;
        width: 150px;
        border-radius: 25px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: #fecc2a;
        transition: all 0.3s;
    }
}

@media screen and (max-width:1050px) {
    .about-buttons-header button{
        margin: 0 20px;
        padding: 10px 20px;
        width: 150px;
        border-radius: 25px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: #fecc2a;
        transition: all 0.3s;
    }
}

@media screen and (max-width:570px) {
    .about-buttons-header{
        margin-top: 370px !important;
    }
    .about-buttons-header button{
        margin: 0 10px;
        padding: 10px 10px;
        width: 100px;
        border-radius: 25px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: #fecc2a;
        transition: all 0.3s;
        font-size: 10px;
    }
    .about-buttons-header button:hover{
        margin: 0;
        padding: 10px 10px;
        margin: 0 10px;
        width: 100px;
        border-radius: 25px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        background-color: #ffffff;
    }
}