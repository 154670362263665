.explore__category {
    min-height: 30vh;
}

.explore__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    margin-top: 30px !important;
}

.explore__feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    cursor: pointer;
    padding: 0 !important;
}

.explore__feature img {
    width: 120px;
    aspect-ratio: 1/1;
    border-radius: 15px;
    border: 5px solid rgb(196, 196, 196);
}

.explore__feature div {
    width: 120px;
    background-color: lightgray;
    aspect-ratio: 1/1;
    border-radius: 15px;
}

.explore__feature span {
    color: #3a3a3a;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 800;
}

.category__heading {
    color: #333333;
}

@media screen and (max-width:768px) {
    .explore__container {
        padding: 20px;
        margin-top: 10px !important;
    }

    .explore__feature img {
        width: 90px;
    }

    .explore__feature span {
        font-size: .55rem;
        font-weight: 800;
    }

}