.accordion-item {
    margin: 10px 5px !important;
    border: 0 !important;
}

.accordion-item-div{
    height: 100%;

}


.accordian-item-day{
    background-color: #ffca00 !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 12px 5px !important;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    height: 100%;
}
.accordion-item {
    margin-bottom: 10px !important;
  }

  .accordian-item-desc{
    font-size: 12px !important;
  }

  .accordian-item-dot{
    font-size: 15px !important;
  }

.accordian-item-button{
    z-index: 0 !important;
    font-size: 0.9rem !important;
    padding: 0 0 0 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.accordian-item-button:focus{
    box-shadow: none !important;
    outline: none !important;
}

.accordian-item-button:not(.collapsed){
    color: inherit !important;
    text-decoration: none !important;
}

@media screen and (max-width:600px) {
    .accordian-item-day{
        background-color: #ffca00 !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 12px 5px !important;
        font-weight: bold;
        font-size: 10px;
        white-space: nowrap;
        height: 100%;
    }
    .accordian-item-button{
        font-size: 0.8rem !important;
        font-weight: bold;
        padding: 0 0 0 10px !important;
    }
}