.why-trust-us {
    width: 100%;
    display: flex;
}

.review-container,
.certified-container {
    width: 50%;
    min-height: 300px;
    margin: 10px 30px;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(236, 236, 236);
    border-radius: 10px;
}

.certified-container {
    flex-direction: column;
    padding-top: 30px;
}

.certified-container h5 {
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.review-container img {
    width: 50px;
}

.certified-container img {
    width: 150px;
}

.review-container h5,
.certified-container h5 {
    margin: 0;
    padding: 0;
}

.review-container p,
.certified-container p {
    margin: 0;
    padding: 0;
}

.google-logo,
.facebook-logo,
.certify-logo {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tripadvisor-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certified-logo-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.certify-logo {
    width: 50%;
    min-height: 100px;
}

@media screen and (max-width:600px) {
    .why-trust-us {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .review-container,
    .certified-container {
        width: 100%;
        min-height: 200px;
        margin: 10px 0;
    }

    .review-container img {
        width: 40px;
    }

    .review-container h5,
    .certified-container h5 {
        margin: 0;
        padding: 0;
        font-size: .8rem;
    }

    .review-container p,
    .certified-container p {
        margin: 0;
        padding: 0;
        font-size: .8rem;
    }

    .certified-container img {
        width: 100px;
    }

    .certified-container h5 {
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 1rem;
    }
}

@media screen and (max-width:400px) {
    .why-trust-us {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .review-container,
    .certified-container {
        width: 100%;
        min-height: 200px;
        margin: 10px 0;
    }

    .review-container img {
        width: 30px;
    }

    .review-container h5,
    .certified-container h5 {
        margin: 0;
        padding: 0;
        font-size: .7rem;
    }

    .review-container p,
    .certified-container p {
        margin: 0;
        padding: 0;
        font-size: .7rem;
    }

    .certified-container img {
        width: 100px;
    }

    .certified-container h5 {
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 1rem;
    }
}