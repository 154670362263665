.career-card{
    border-radius: 20px !important;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.career-card:hover{
    transform: scale(1.02) !important;
}

.career-apply-button button {
    padding: 14px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: 0;
    margin: 5px 25px;
    background-color: #ffca00;
    color: black;
}


.career-apply-button button:hover {
    background-color: #ecb605;
}
.career-apply-button button:active {
    background-color: #fbd864 !important;
}
.career-apply-form input:focus{
    outline: none !important;
}

.career-apply-form input, select, option, textarea{
    font-size: 14px !important;
}
.career-apply-form select:focus{
    outline: none !important;
}
.career-apply-form textarea:focus{
    outline: none !important;
}
.career-apply-form-lable{
    color: gray;
    position: absolute;
    top: -8px;
    left: 20px;
    background-color: white;
    margin: 0;
    padding: 0;
    padding: 0 10px;
    font-size: 12px;
}


@media screen and (max-width:800px) {

    .career-apply-form input, select, option, textarea{
        font-size: 16px !important;
    }
}