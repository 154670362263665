/* 6c6c6c */
/* eeb419 - yellow*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none !important;
}


.Mon_font {
    font-family: 'Montserrat', sans-serif;
}

.hero__heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5vw;
    font-weight: 600;
    margin-bottom: 2vh;
}

.sub__heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 2vw;
    font-weight: 300;
    margin-top: 90px !important;
}

.category__heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5vw;
    font-weight: 600;
}

.content_text_grey {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vw;
    font-weight: 500;
    color: grey;

}

.uppercase {
    text-transform: uppercase;
}

.thick-hr {
    border: none;
    height: 5px;
    display: block;
    margin: 0 auto 20px auto;
    background-color: #ffca00 !important;
    width: 100px;
}



.hr-line {
    height: 3px;
    color: #ffca00;
    background-color: #ffca00;
    width: 120px;
    display: block;
    margin: auto;
}


.blog-desc-link{
    color: blue !important;
    font-weight: 600;
    text-decoration: underline !important;
}

@media screen and (max-width:600px) {
    .hero__heading {
        width: 100%;
        font-size: 1.8rem;
        text-align: center;
        text-align: center;
    }

    .sub__heading {
        width: 100%;
        font-weight: 300;
        font-size: 1.2rem;
        color: grey;
        text-align: center;
        margin-top: 70px !important;
    }

    .category__heading {
        font-size: 5vw;
    }

    .content_text_grey {
        font-size: 3.2vw;
    }
}

@media screen and (max-width:500px) {
    .hero__heading {
        font-size: 2.5rem;
        text-align: center;
    }

    .sub__heading {
        width: 100%;
        font-weight: 300;
        font-size: 1.2rem;
        color: #eee;
        text-align: center;
        margin: 50px 0;
    }

    .hr-line {
        width: 80px;
    }
}