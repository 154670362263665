.main {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    color: white;
    padding: 0 20px;
}

.modal-body {
    z-index: 99999999999999 !important;
}

.modal-backdrop {
    z-index: 0 !important;
}

.navbar {
    background: transparent;
    transition: .3s ease;
    z-index: inherit !important; 
}

.navbar.active {
    background: #141414;
}

.logo,
.contact_number {
    width: 20%;
}

.logo,
.contact_number span,
.menu_list ul li a {
    color: rgb(231, 231, 231) !important;
    font-family: 'Montserrat', sans-serif;
    margin: 0 10px;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
}

.menu_list ul {
    margin: 0;
}

.nav_toggle_button i {
    font-size: 20px;
}

@media screen and (max-width:600px) {

    .logo,
    .contact_number {
        width: auto;
    }

    .contact_number {
        margin-left: -15px;
    }
}