.whatsapp-button{
    position: fixed !important;
    bottom: 30px !important;
    right: 30px !important;
    z-index: 111111111111;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}
.mobile-whatsapp-button{
    display: none;
}

.whatsapp-button:hover .whatsapp-button-div img{
    width: 55px;
}
.whatsapp-button .whatsapp-button-div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.whatsapp-button img{
    width: 50px;
    transition: all 0.2s ease;
}


.whatsapp-div{
    position: fixed;
    bottom: 100px;
    right: 80px;
    height: 270px;
    background-color: rgb(255, 255, 255);
    width: 230px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 111111111;
    flex-direction: column;
}

.whatsapp-div-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.whatsapp-div-text{
    cursor: pointer;
    transition: all 0.2s ease !important;
}


@media screen and (max-width: 480px) {
    .whatsapp-button{
        display: none !important;
    }
    .mobile-whatsapp-button{
        display: block;
        height: 54px;
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        z-index: 11111111111;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
    }
    .mobile-whatsapp-wrap-1{
        width: 33.33%;
    }
    .mobile-whatsapp-wrap-2{
        width: 33.33%;
    }
    .mobile-whatsapp-wrap-3{
        width: 33.33%;
    }
    .mobile-whatsapp-wrap-1:hover{
        background-color: #ffca00;
    }
    .mobile-whatsapp-wrap-2:hover{
        background-color: #ffca00;
    }
    .mobile-whatsapp-wrap-3:hover{
        background-color: #ffca00;
    }
}