.video__cont {
    position: relative;
}

.video__cont .arrow__left,
.video__cont .arrow__right {
    background-color: #000000c9 !important;
    color: white;
    position: absolute;
    top: 150px;
    padding: 25px 0;
    width: 30px;
}

.trending-arrow__left,
.trending-arrow__right {
    top: 270px !important;
}

.video__cont .arrow__left {
    left: -40px;
}
.video__cont .arrow__left:hover {
    background-color: #4e4e4ec9 !important;
}

.video__cont .arrow__right {
    right: -40px;
}
.video__cont .arrow__right:hover {
    background-color: #4e4e4ec9 !important;
}

.video iframe {
    border-radius: 15px;
}
.video__cont .hr-line-head{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.video__cont .hr-line-head div{
    width: 9%;
}

.video__cont h2{
    text-align: center !important;
    font-weight: bold;
}
.video__cont .video iframe{
    border-radius: 40px !important;
}
.video__cont .accomodation-notes{
    margin: 0 !important;
    text-align: center;
    line-height: 23px;
    font-size: 18px;
}
.video__cont .accomodation-notes-parent{
    margin: auto;
    width: 60%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
}

.video iframe{
    width: 500px !important;
    height: 250px !important;
}



@media screen and (max-width:990px) {
    .video__cont .accomodation-notes{
        margin: 0 !important;
        text-align: center;
        line-height: 18px;
        font-size: 14px;
    }
    .video__cont .accomodation-notes-parent{
        padding: 0 22px !important;
        width: 100%;
        margin: 0 !important;
        text-align: center;
        margin-top: 30px;
    }
}

@media screen and (max-width:478px) {
    .video iframe{
        width: 300px !important;
        height: 165px !important;
    }

    .video__cont .arrow__left {
        left: -30px;
        display: none;
    }

    .video__cont .arrow__right {
        right: -30px;
        display: none;
    }
    .video__cont .hr-line-head{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .video__cont .hr-line-head div{
        width: 21%;
    }
    .video__cont h2{
        text-align: center !important;
        font-weight: bold;
    }
}