.trending__location {
    padding: 0 50px;
}

.trending__location h2 {
    color: #333333;
    text-align: center;
}

.trending__banner {
    width: 95% !important;
    height: 500px;
    border-radius: 15px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.trending__banner h4 {
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Monserrat', sans-serif;
}

@media screen and (max-width:1000px) {
    .trending__banner {
        height: 650px;
    }
}
@media screen and (max-width:600px) {
    .trending__banner {
        height: 400px;
    }
}
@media screen and (max-width:478px) {
    .trending__location {
        padding: 0 10px;
    }
}