.popup__form {
    position: fixed;
    bottom: -40px;
    background-color: #fff;
    z-index: 500;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease;
    border-radius: 50px 50px 0 0;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* ==================================== Timer Form CSS  */
.timer_form_cont {
    position: fixed;
    top: 80px;
    left: 100px;
    background-color: #fff;
    z-index: 50000;
    height: 80vh;
    margin: 0;
    padding: 30px 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
}

.timer_form_cont .fa-xmark {
    background-color: rgb(52, 100, 133);
    color: white;
    padding: 5px 8px !important;
    cursor: pointer !important;
}

.cursor-pointer {
    cursor: pointer;
}

.timerform__img {
    width: 350px !important;
    border-radius: 15px;
}

.timerForm h3 {
    font-weight: 800;
    font-size: 30px;
    margin: 20px 0;
    color: rgb(52, 100, 133);
}

.timerForm button {
    background-color: rgb(52, 100, 133);
    color: #fff;
    padding: 10px 40px;
    display: block;
    margin: 0 auto;
    width: 200px;
    border: 0;
    border-radius: 15px;
}

.timer_form_cont .f20 {
    font-size: 30px !important;
}

@media screen and (max-width:600px) {
    .popup__form {
        left: 0;
        bottom: 0;
        height: 600px !important;
    }

    form button {
        padding: 13px 30px !important;
        margin: 10px 0;
    }

    form input {
        padding: 10px 10px !important;
    }

    /* ==================================== Timer Form CSS  */
    .timerForm h3 {
        font-weight: 800;
        font-size: 30px;
        margin-top: 0 !important;
        margin-bottom: 10px !important;
        color: rgb(52, 100, 133);
    }

    .timerform__img {
        width: 300px !important;
        border-radius: 15px;
    }

    .timer_form_cont {
        padding: 20px 20px !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 85vh;
        width: 90%;
        border-radius: 5px;
        z-index: 99999;
    }

    .timerForm button {
        padding: 10px 20px !important;
    }

    .timer_form_cont .f20 {
        font-size: 30px !important;
    }

}

@media screen and (max-width:400px) {
    .f20 {
        font-size: 20px !important;
    }

    .timer-img-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    .timerform__img {
        width: 300px !important;
        border-radius: 15px;
        height: 90%;
    }

    .timer_form_cont {
        padding: 10px !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90vh;
        width: 90%;
        border-radius: 5px;
    }

    .timer_form_cont h3 {
        font-size: 14px;
        margin-top: 0;
    }

    .timerForm input {
        padding: 5px !important;
    }
}