.slider__trip {
    position: relative;
    padding: 30px;
}

.slider__trip .blog-title {
    font-size: 18px !important;
}

.slider__trip .blog-para {
    font-size: 15px !important;
}
.slider__trip .blog-div {
    margin-top: -6px !important;
    margin-right: 20px !important;
}

.trips {
    position: relative;
    width: 300px;
    cursor: pointer !important;
    box-sizing: border-box;
    overflow: hidden;
}

.trip__image {
    margin: 10px;
    position: relative;
    width: 90%;
    height: 15vw;
    border-radius: 2.5vw;
    transition: all 0.3s ease;
    scale: 1;
}

.trips:hover img {
    scale: 1.02;
}

.trips h5 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #333333;
}

.price {
    color: #333333;
    font-weight: 600 !important;
}

.date__time i {
    font-size: 16px;
}

.trips p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: rgb(95, 95, 95);
}

.trips i {
    color: #ffc400;
    font-size: 25px;
}

.trips h6 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #ffc400;

}

.slider__trip .slick-list {
    padding: 0px !important;
}

.dates {
    min-width: 250px;
    display: flex;
    flex-wrap: wrap !important;
}

.dates i {
    color: #ffc400;
    font-size: 25px;
}

.dates span {
    color: rgb(122, 122, 122);
    font-size: 15px;
    font-weight: 600;
}

.prevArrow,
.nextArrow {
    position: absolute;
    top: 30%;
    border: 0;
    font-size: 4rem;
    padding: 10px;
    background-color: transparent;
    color: #eeb419;
}

.slider__trip .arrow__left,
.slider__trip .arrow__right {
    background-color: #000000c9 !important;
    color: white;
    position: absolute;
    top: 150px;
    padding: 25px 0;
    width: 30px;
}

.slider__trip .arrow__left {
    left: -25px;
}

.slider__trip .arrow__left:hover {
    background-color: #4e4e4ec9 !important;
}

.slider__trip .arrow__right {
    right: -5px;
}

.slider__trip .arrow__right:hover {
    background-color: #4e4e4ec9 !important;
}


@media screen and (max-width:992px) {
    .slider__trip{
        padding: 10px;
    }
    .slider__trip .blog-title {
        font-size: 15px !important;
    }
    .slider__trip .blog-para {
        font-size: 11px !important;
    }
    .slider__trip .blog-div {
        margin-right: 10px !important;
    }
    .trips {
        min-width: 200px;
        width: 100%;
        padding: 0 0 0 10px;
    }

    .trips img {
        width: 95%;
        height: 45vw;
        border-radius: 30px;
    }

    .date__time i {
        font-size: 20px;
    }

    .trips p {
        font-size: 3vw;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .trips h5 {
        font-size: 3.5vw;
        min-width: 220px;
        font-weight: 700;
    }

    .dates i {
        font-size: 20px;
    }

    .dates span {
        font-size: 12px;
    }

    /* .slider__trip .slick-slide {} */

    .slider__trip .arrow__left,
    .slider__trip .arrow__right {
        display: none;
        position: absolute;
        padding: 10px;
        top: 0;
    }
}

@media screen and (max-width:400px) {
    .date__time i {
        font-size: .9rem;
    }

    .trips p {
        font-size: .65rem;
    }

    .trips h5 {
        font-weight: 700;
        font-size: .8rem;
    }

    .dates span {
        font-weight: 600;
        font-size: 10px;
    }

    .slider__trip .arrow__left,
    .slider__trip .arrow__right {
        display: none;
        position: absolute;
        padding: 10px;
        top: 0;
    }
}