.category__cont h2 {
    border-left: 5px solid #333333;
    padding: 10px;
}

.category__cont select {
    color: #333333;
    border: 1px solid #333333;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 5px;
    outline: none;
}

.trip__container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 360px;
    padding-left: 0;
    border-radius: 10px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
}

.trip__img__container {
    border-radius: 10px;
    overflow: hidden;
    width: 375px;
    height: 200px;
    margin-left: -10px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.other__content {
    margin-bottom: 10px;
    padding: 10px !important;
    width: 100% !important;
}

.dates p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .dates p {
        font-size: 12px;
    }
    .trip__title{
        font-size: 15px !important;
    }
}